import { capitalize, PricingProcessor, StationType, UserRole } from "common";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import CustomDropdownBlack from "../../components/common/CustomDropdownBlack";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import MenuPopover from "../../components/header/MenuPopover";
import {
    getLocalSettings as getLocalSettingsLocalStorage,
    setLocalSettings as setLocalSettingsLocalStorage,
} from "../../utils/localStorage";
import GeneratedInputText from "../ProcessPage/Components/GeneratedInputField";

export type LocalSettings = {
    stationType?: StationType;
    aiGenerateProcessor?: PricingProcessor;
    cameraRapidPricing?: string;
    cameraRotation?: string;
    cameraFormat?: string;
    cameraResolution?: string;
    aiAssistantImageCount?: string;
    labelPrinterServer?: string;
};

export default function SettingsPage() {
    const { role, email } = useContext(OrgContext);
    const [localSettings, setLocalSettings] = useState<LocalSettings>(getLocalSettingsLocalStorage());

    useEffect(() => {
        setLocalSettingsLocalStorage(localSettings);
    }, [localSettings]);

    return (
        <div className="h-dvh overflow-y-clip overscroll-none">
            <Header
                darkMode
                left={<MenuPopover role={role as UserRole} darkMode />}
                right={!isMobile && <div className="flex justify-end pr-4 text-thriftlyOffWhite">{email}</div>}
            />
            <div className="flex flex-col items-center border-t-2 border-thriftlyGreyDark pt-2">
                <div className="flex w-96 flex-col gap-2">
                    <div className="flex min-h-24 flex-col gap-8">
                        <div className="text-center text-2xl text-white">Settings</div>
                        <div className="grid grid-cols-2 items-center">
                            <div className="text-white">Station type</div>
                            <CustomDropdownBlack
                                placeholder="Select station type"
                                options={Object.values(StationType).map((option) => {
                                    return {
                                        label: capitalize(option.replace("_", " ")),
                                        value: option,
                                    };
                                })}
                                value={localSettings.stationType}
                                capitalize
                                onChange={(value) => {
                                    setLocalSettings({ ...localSettings, stationType: value as StationType });
                                }}
                            />
                            <div className="text-white">AI Generate processor</div>
                            <CustomDropdownBlack
                                placeholder="AI Generate processor"
                                options={Object.values(PricingProcessor)
                                    .filter((option) => option !== PricingProcessor.ProcessorSerpApi)
                                    .map((option) => {
                                        return {
                                            label: capitalize(option.replace("_", " ")),
                                            value: option,
                                        };
                                    })}
                                value={localSettings.aiGenerateProcessor}
                                capitalize
                                onChange={(value) => {
                                    setLocalSettings({
                                        ...localSettings,
                                        aiGenerateProcessor: value as PricingProcessor,
                                    });
                                }}
                            />
                            <div className="text-white">Camera for rapid pricing</div>
                            <CustomDropdownBlack
                                placeholder="Select camera enabled for rapid pricing"
                                options={[
                                    { label: "Enabled", value: "true" },
                                    { label: "Disabled", value: "false" },
                                ]}
                                value={localSettings.cameraRapidPricing}
                                onChange={(value) => {
                                    setLocalSettings({ ...localSettings, cameraRapidPricing: value });
                                }}
                            />
                            <div className="text-white">Camera rotation</div>
                            <CustomDropdownBlack
                                placeholder="Select camera rotation"
                                options={[
                                    { label: "0°", value: "0" },
                                    // { label: "90°", value: "90" },
                                    { label: "180°", value: "180" },
                                    // { label: "270°", value: "270" },
                                ]}
                                value={localSettings.cameraRotation}
                                onChange={(value) => {
                                    setLocalSettings({ ...localSettings, cameraRotation: value });
                                }}
                            />
                            <div className="text-white">Camera format</div>
                            <CustomDropdownBlack
                                placeholder="Select camera format"
                                options={[
                                    { label: "Square", value: "square" },
                                    { label: "Landscape", value: "landscape" },
                                    { label: "Portrait", value: "portrait" },
                                ]}
                                value={localSettings.cameraFormat}
                                onChange={(value) => {
                                    setLocalSettings({ ...localSettings, cameraFormat: value });
                                }}
                            />
                            <div className="text-white">Camera resolution</div>
                            <CustomDropdownBlack
                                placeholder="Select camera resolution"
                                options={[
                                    { label: "1080p", value: "1080p" },
                                    { label: "720p", value: "720p" },
                                    { label: "480p", value: "480p" },
                                ]}
                                value={localSettings.cameraResolution}
                                onChange={(value) => {
                                    setLocalSettings({ ...localSettings, cameraResolution: value });
                                }}
                            />
                            <div className="text-white">AI Assistant Image Count</div>
                            <CustomDropdownBlack
                                placeholder="Select image count"
                                options={[
                                    { label: "2", value: "2" },
                                    { label: "3", value: "3" },
                                    { label: "4", value: "4" },
                                    { label: "5", value: "5" },
                                    { label: "6", value: "6" },
                                    { label: "7", value: "7" },
                                    { label: "8", value: "8" },
                                    { label: "9", value: "9" },
                                    { label: "10", value: "10" },
                                ]}
                                value={localSettings.aiAssistantImageCount}
                                onChange={(value) => {
                                    setLocalSettings({ ...localSettings, aiAssistantImageCount: value });
                                }}
                            />
                            <div className="text-white">Label Printer Server</div>
                            <div className="p-2">
                                <GeneratedInputText
                                    labelTextColor="text-thriftlyGreyLight"
                                    textColor="text-thriftlyOffWhite"
                                    backgroundColor="bg-thriftlyBlackBackground"
                                    placeholder="127.0.0.1:9100"
                                    type="text"
                                    value={localSettings.labelPrinterServer}
                                    onChange={(value) => {
                                        setLocalSettings({ ...localSettings, labelPrinterServer: value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
