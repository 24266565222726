import React, { forwardRef, ReactNode, useImperativeHandle, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import Webcam from "react-webcam";
import { rotate } from "../../utils/camera";
import { getLocalSettings } from "../../utils/localStorage";
import useCameraPermission from "../common/useCameraPermissions";
import { Button } from "./Button";
import Loader from "./Loader";

export interface CameraRef {
    capture(price?: string, condition?: string, category?: string, gender?: string, pricingEntryId?: string): void;
}

export default forwardRef(function Camera(
    {
        width,
        children,
        onImageCapture,
    }: {
        width?: string;
        children?: ReactNode;
        onImageCapture: (
            userImage: string,
            setIsLoading: (isLoading: boolean) => void,
            closeCamera: () => void,
            clearImage: () => void,
            price?: string,
            condition?: string,
            category?: string,
            gender?: string,
            pricingEntryId?: string
        ) => void;
    },
    ref
) {
    const cameraPermission = useCameraPermission();
    const [userImage, setUserImage] = useState<string | null>(null);
    const webcamRef = useRef<Webcam>(null);

    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        capture(price?: string, condition?: string, category?: string, gender?: string, pricingEntryId?: string) {
            capture(price, condition, category, gender, pricingEntryId);
        },
    }));

    const getWidth = (function () {
        if (width) return width;

        if (isMobile) {
            switch (getLocalSettings().cameraFormat) {
                case "portrait":
                    return "w-3/4";
                case "landscape":
                    return "w-full";
                case "square":
                default:
                    return "w-full";
            }
        }

        switch (getLocalSettings().cameraFormat) {
            case "portrait":
                return "w-1/5";
            case "landscape":
                return "w-1/2";
            case "square":
            default:
                return "w-1/3";
        }
    })();

    const cameraFormat = (function () {
        if (isMobile) {
            switch (getLocalSettings().cameraFormat) {
                case "portrait":
                    return 3 / 2;
                case "landscape":
                    return 9 / 16;
                case "square":
                default:
                    return 1;
            }
        }

        switch (getLocalSettings().cameraFormat) {
            case "portrait":
                return 2 / 3;
            case "landscape":
                return 16 / 9;
            case "square":
            default:
                return 1;
        }
    })();

    const cameraResolution = (function () {
        switch (getLocalSettings().cameraResolution) {
            case "720p":
                switch (getLocalSettings().cameraFormat) {
                    case "portrait":
                        return { width: 480, height: 720 };
                    case "landscape":
                        return { width: 1280, height: 720 };
                    case "square":
                    default:
                        return { width: 720, height: 720 };
                }
            case "480p":
                switch (getLocalSettings().cameraFormat) {
                    case "portrait":
                        return { width: 320, height: 480 };
                    case "landscape":
                        return { width: 640, height: 480 };
                    case "square":
                    default:
                        return { width: 480, height: 480 };
                }
            case "1080p":
            default:
                switch (getLocalSettings().cameraFormat) {
                    case "portrait":
                        return { width: 720, height: 1080 };
                    case "landscape":
                        return { width: 1920, height: 1080 };
                    case "square":
                    default:
                        return { width: 1080, height: 1080 };
                }
        }
    })();

    const videoConstraints = (function () {
        return {
            facingMode: "environment",
            aspectRatio: cameraFormat,
        };
    })();

    const closeCamera = () => {
        if (webcamRef.current && webcamRef.current.stream) {
            webcamRef.current.stream.getTracks().forEach((track) => track.stop());
        }
    };

    const clearImage = () => {
        setUserImage(null);
    };

    const capture = React.useCallback(
        async (price?: string, condition?: string, category?: string, gender?: string, pricingEntryId?: string) => {
            if (webcamRef.current && webcamRef.current.stream) {
                const imageSrc = webcamRef.current.getScreenshot(cameraResolution);

                rotate(imageSrc!, Number(getLocalSettings().cameraRotation)).then((rotatedImageSrc) => {
                    setUserImage(rotatedImageSrc);
                    onImageCapture(
                        rotatedImageSrc!,
                        setIsLoading,
                        closeCamera,
                        clearImage,
                        price,
                        condition,
                        category,
                        gender,
                        pricingEntryId
                    );
                });
            }
        },
        [webcamRef, setUserImage]
    );

    if (cameraPermission !== "granted") {
        return (
            <div className={`${getWidth} flex flex-col items-center justify-center`}>
                <div className="inline-block flex w-full flex-col gap-4 p-4">
                    <div className="flex w-full items-center justify-center">
                        <Loader />
                    </div>
                    <div className="w-full p-4">
                        <Button className="w-full" onClick={() => window.location.reload()}>
                            Allow camera permission
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`${getWidth} flex h-full flex-col items-center`}>
            <div className="w-full overflow-hidden rounded-thriftlyCamera">
                <div className={`inline-block flex flex-col gap-4 p-4`}>
                    {userImage ? (
                        <img
                            className={`z-50 overflow-hidden rounded-thriftlyCamera`}
                            src={userImage || ""}
                            alt="Donation"
                        />
                    ) : (
                        <Webcam
                            audio={false}
                            className={`w-full ${"rotate- transform"}${getLocalSettings().cameraRotation} overflow-hidden rounded-thriftlyCamera`}
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            mirrored={false}
                            screenshotQuality={1}
                            imageSmoothing={false}
                            videoConstraints={videoConstraints}
                        />
                    )}
                    {isLoading ? (
                        <div className="flex justify-center">
                            <Loader />
                        </div>
                    ) : (
                        <>{children}</>
                    )}
                </div>
            </div>
        </div>
    );
});
