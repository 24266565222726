import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import {
    capitalize,
    ErrorResponse,
    LocationStyles,
    NewZebraBrowserPrint,
    PricingProcessor,
    StationType,
    User,
    UserRole,
    UsersResponse,
    ZebraPrinter,
} from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { MdAudiotrack, MdCircle, MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import useWebSocket, { ReadyState } from "react-use-websocket";
import BarcodeListener from "../../components/BarcodeListener";
import Loader from "../../components/common/Loader";
import { ApiContext } from "../../components/context/ApiContext";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import MenuPopover from "../../components/header/MenuPopover";
import { ScannerResponse, UserSession } from "../../types";
import {
    getLocalSettings,
    getOrgStyles,
    getUserSessions,
    setPriceAppConfig,
    setUserSessions,
} from "../../utils/localStorage";

export default function ProductionPage() {
    const { VITE_API_BASE_URL } = import.meta.env;
    const { orgId, role, email } = useContext(OrgContext);
    const { login: usersApi, organizations: organizationsApi, processes: processApi } = useContext(ApiContext);
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());
    const { lastMessage, sendJsonMessage, readyState } = useWebSocket("ws://localhost:8089", {
        shouldReconnect: () => true, // Always try to reconnect
        reconnectAttempts: 100000, // Always retry
        reconnectInterval: 3 * 1000, // Reconnect attempt interval in milliseconds
    });

    const [isInitializing, setIsInitializing] = useState(true);
    const userSessions = useRef<UserSession[]>(getUserSessions());
    const [userSessionsState, setUserSessionsState] = useState<UserSession[]>(getUserSessions());
    const printers = useRef<ZebraPrinter[]>([]);

    useEffect(() => {
        // get price app config
        organizationsApi?.priceAppConfigDetail(orgId).then((response) => {
            if (response.data.priceAppConfig) setPriceAppConfig(response.data.priceAppConfig);
        });

        // initialize printers
        NewZebraBrowserPrint(getLocalSettings().labelPrinterServer)
            .getPrinters()
            .then((response) => {
                printers.current = response;

                userSessions.current.forEach((userSession) => {
                    const printer = printers.current.find(
                        (printer: ZebraPrinter) => printer.info.uid === userSession.printerId
                    );
                    if (printer) {
                        userSession.printer = printer;
                    } else {
                        userSession.printerId = undefined;
                    }
                });
            })
            .catch((error) => toast(`Error getting printers. Check browser print service: ${error}`))
            .finally(() => setIsInitializing(false));
    }, []);

    const finishSetup = (userSession: UserSession) => {
        if (userSession.userId && userSession.user && userSession.printerId && userSession.printer) {
            userSession.status = "#00FF00";
            sendJsonMessage(
                JSON.stringify({
                    scannerId: userSession.scannerId,
                    commandType: "led",
                    ledColor: "green",
                    ledStatus: false,
                })
            );
            sendJsonMessage(
                JSON.stringify({
                    scannerId: userSession.scannerId,
                    commandType: "beep",
                    beep: 1,
                })
            );
            toast(`User session setup complete: ${userSession.user.name}`);
        } else {
            sendJsonMessage(
                JSON.stringify({
                    scannerId: userSession.scannerId,
                    commandType: "led",
                    ledColor: "yellow",
                    ledStatus: true,
                })
            );
            userSession.status = "#FFFF00";
        }
        userSessions.current = userSessions.current.map((session) =>
            session.scannerId === userSession.scannerId ? userSession! : session
        );
        setUserSessionsState(userSessions.current);
        setUserSessions(userSessions.current);
    };

    const handleUserScan = (scannerId: number, userId: string) => {
        usersApi
            ?.usersList()
            .then(({ data }: { data: UsersResponse }) => {
                if (data.users) {
                    const user = data.users.find((user) => user.id === userId);
                    if (user) {
                        // Initialize new user session, or override existing one
                        let userSession = userSessions.current.find((session) => session.scannerId === scannerId);
                        if (!userSession) {
                            userSession = {
                                scannerId: scannerId!,
                                userId: userId,
                                user: user,
                            };
                            userSessions.current = [...userSessions.current, userSession];
                            finishSetup(userSession);
                        } else {
                            userSession.userId = userId;
                            userSession.user = user;
                            userSessions.current = userSessions.current.map((session) =>
                                session.scannerId === scannerId ? userSession! : session
                            );
                            finishSetup(userSession);
                        }
                        sendJsonMessage(
                            JSON.stringify({
                                scannerId: scannerId,
                                commandType: "beep",
                                beep: 1,
                            })
                        );
                        updateItemsTodayCount(scannerId, userId);
                    } else {
                        sendJsonMessage(
                            JSON.stringify({
                                scannerId: scannerId,
                                commandType: "beep",
                                beep: 6,
                            })
                        );
                        toast(`Error getting user for id: ${userId}`);
                        console.log(`Error getting user for id: ${userId}`);
                    }
                }
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                console.log((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    const handlePrinterScan = (scannerId: number, printerId: string, stationType: StationType) => {
        // Set printer on an existing user session, if exists
        let userSession = userSessions.current.find((session) => session.scannerId === scannerId);
        if (!userSession) {
            userSession = { scannerId: scannerId!, printerId: printerId };
            const printer = printers.current.find(
                (printer: ZebraPrinter) => printer.info.uid === userSession!.printerId
            );
            if (printer) {
                userSession!.printer = printer;
                userSession!.stationType = stationType;
                userSessions.current = [...userSessions.current, userSession!];
                finishSetup(userSession!);
                sendJsonMessage(
                    JSON.stringify({
                        scannerId: scannerId,
                        commandType: "beep",
                        beep: 1,
                    })
                );
            } else {
                sendJsonMessage(
                    JSON.stringify({
                        scannerId: scannerId,
                        commandType: "beep",
                        beep: 6,
                    })
                );
                userSession!.printerId = undefined;
                toast(`Error getting printer for id: ${printerId}`);
                console.log(`Error getting printer for id: ${printerId}`);
            }
        } else {
            userSession.printerId = printerId;
            const printer = printers.current.find(
                (printer: ZebraPrinter) => printer.info.uid === userSession!.printerId
            );
            if (printer) {
                userSession!.printer = printer;
                userSession!.stationType = stationType;
                userSessions.current = userSessions.current.map((session) =>
                    session.scannerId === scannerId ? userSession! : session
                );
                finishSetup(userSession!);
                sendJsonMessage(
                    JSON.stringify({
                        scannerId: scannerId,
                        commandType: "beep",
                        beep: 1,
                    })
                );
            } else {
                sendJsonMessage(
                    JSON.stringify({
                        scannerId: scannerId,
                        commandType: "beep",
                        beep: 6,
                    })
                );
                userSession!.printerId = undefined;
                toast(`Error getting printer for id: ${printerId}`);
                console.log(`Error getting printer for id: ${printerId}`);
            }
        }
    };

    const updateItemsTodayCount = (scannerId: number, userId: string) => {
        processApi
            ?.itemsTodayList({ userId })
            .then((resp) => {
                const userSession = userSessions.current.find((session) => session.scannerId === scannerId);
                if (userSession) {
                    userSession.itemsToday = resp.data.itemsToday;
                    userSessions.current = userSessions.current.map((session) =>
                        session.scannerId === scannerId ? userSession! : session
                    );
                    setUserSessionsState(userSessions.current);
                    setUserSessions(userSessions.current);
                }
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    const handleFixedPriceScan = (
        scannerId: number | undefined,
        price?: string,
        condition?: string,
        category?: string,
        gender?: string,
        stationType?: StationType
    ) => {
        handlePriceScan(scannerId, price, condition, category, gender, stationType);
    };

    const handlePricingEntryScan = (scannerId: number | undefined, pricingEntryId: string) => {
        handlePriceScan(scannerId, undefined, undefined, undefined, undefined, undefined, pricingEntryId);
    };

    const handlePriceScan = async (
        scannerId: number | undefined,
        price?: string,
        condition?: string,
        category?: string,
        gender?: string,
        stationType?: StationType,
        pricingEntryId?: string
    ) => {
        // Process item without a picture and print a price label
        const userSession = userSessions.current.find((session) => session.scannerId === scannerId);
        if (userSession === undefined || userSession.userId === undefined || userSession.printerId === undefined) {
            sendJsonMessage(
                JSON.stringify({
                    scannerId: scannerId,
                    commandType: "beep",
                    beep: 6,
                })
            );
            return;
        }

        await processApi
            ?.processesCreate(
                {},
                {
                    userId: userSession.userId,
                    price,
                    condition,
                    category,
                    gender,
                    processor: price !== undefined && price !== "" ? PricingProcessor.ProcessorNone : undefined,
                    stationType,
                    pricingEntryId,
                }
            )
            .then(async (resp) => {
                updateItemsTodayCount(userSession!.scannerId, userSession!.userId!);
                const url = VITE_API_BASE_URL + "/processes/" + resp.data.process?.id + "/label?print=true";
                userSession!.printer!.printZPL(url).catch((error) => {
                    sendJsonMessage(
                        JSON.stringify({
                            scannerId: scannerId,
                            commandType: "beep",
                            beep: 6,
                        })
                    );
                    toast(`Error printing label: ${error}`);
                    console.error(`Error printing label: ${error}`);
                });
            })
            .catch((err: AxiosError) => {
                sendJsonMessage(
                    JSON.stringify({
                        scannerId: scannerId,
                        commandType: "beep",
                        beep: 6,
                    })
                );
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                console.log(
                    (err.response?.data as ErrorResponse)?.error ||
                        err.message ||
                        "An error occurred. Please try again."
                );
            });
    };

    const identifyScanner = (scannerId: number) => {
        sendJsonMessage(
            JSON.stringify({
                scannerId: scannerId,
                commandType: "beep",
                beep: 14,
            })
        );
    };

    const deleteUserSession = (scannerId: number, setLed: boolean) => {
        if (setLed) {
            sendJsonMessage(
                JSON.stringify({
                    scannerId: scannerId,
                    commandType: "led",
                    ledColor: "red",
                    ledStatus: true,
                })
            );
        }
        userSessions.current = userSessions.current.filter((session) => session.scannerId !== scannerId);
        setUserSessionsState(userSessions.current);
        setUserSessions(userSessions.current);
    };

    useEffect(() => {
        if (lastMessage !== null) {
            try {
                const msg: ScannerResponse = JSON.parse(lastMessage.data);
                const userSession = userSessions.current.find((session) => session.scannerId === msg.scannerId);
                switch (msg.messageType) {
                    case "response":
                        if (msg.status === false && userSession !== undefined) {
                            console.log("msg.status === false && userSession");
                            userSession.status = "#FF0000";
                            userSessions.current = userSessions.current.map((session) =>
                                session.scannerId === userSession!.scannerId ? userSession! : session
                            );
                            setUserSessionsState(userSessions.current);
                            setUserSessions(userSessions.current);
                        }
                        break;
                    case "attached":
                        if (userSession) {
                            finishSetup(userSession);
                        }
                        break;
                    case "detached":
                        if (userSession) {
                            userSession.status = "#FF0000";
                            setUserSessionsState(userSessions.current);
                            setUserSessions(userSessions.current);
                        }
                        break;
                    default:
                }
            } catch (e) {
                if (e instanceof Error) {
                    toast(`Error parsing scanner response JSON: ${e.message}`);
                }
                toast("Unknown error parsing scanner response JSON");
            }
        }
    }, [lastMessage]);

    const getBody = () => {
        return (
            <div className="min-h-[400px] px-4">
                <ThemeProvider theme={createTheme({ palette: { mode: "dark" } })}>
                    <DataGrid
                        sx={{
                            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                                py: "8px",
                            },
                            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                                py: "15px",
                            },
                            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                                py: "22px",
                            },
                            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                                outline: "none",
                            },
                            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                                outline: "none",
                            },
                        }}
                        getRowId={(row) => row.scannerId}
                        rows={
                            [...userSessionsState]?.sort(
                                (a: UserSession, b: UserSession) => a.scannerId - b.scannerId
                            ) || []
                        }
                        columns={[
                            {
                                field: "user",
                                headerName: "User",
                                minWidth: 150,
                                flex: 1,
                                resizable: false,
                                valueGetter: (value: User) => value?.name,
                            },
                            {
                                field: "printer",
                                headerName: "Printer",
                                minWidth: 150,
                                flex: 1,
                                resizable: false,
                                valueGetter: (printer: ZebraPrinter, userSession: UserSession) => {
                                    if (!printer) return "";
                                    return `${printer?.info.name} (${capitalize(userSession.stationType!.replace("_", " "))})`;
                                },
                            },
                            {
                                field: "itemsToday",
                                headerName: "Items Today",
                                resizable: false,
                                valueGetter: (value: User) => value || 0,
                            },
                            {
                                field: "status",
                                headerName: "Status",
                                sortable: false,
                                resizable: false,
                                renderCell: (value) => {
                                    return <MdCircle fill={value.row.status} className="h-[24px] w-[24px]" />;
                                },
                            },
                            {
                                field: "action",
                                headerName: "Actions",
                                sortable: false,
                                resizable: false,
                                renderCell: (value) => {
                                    return (
                                        <div className="flex flex-row gap-4">
                                            <MdAudiotrack
                                                className="h-[24px] w-[24px] cursor-pointer"
                                                onClick={() => {
                                                    identifyScanner(value.row.scannerId);
                                                }}
                                            />
                                            <MdDelete
                                                className="h-[24px] w-[24px] cursor-pointer"
                                                onClick={() => {
                                                    deleteUserSession(value.row.scannerId, true);
                                                }}
                                            />
                                        </div>
                                    );
                                },
                            },
                        ]}
                        getRowHeight={() => "auto"}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                        disableColumnMenu
                    />
                </ThemeProvider>
            </div>
        );
    };

    return (
        <div className="flex h-dvh flex-col">
            <BarcodeListener
                barcodeData={lastMessage?.data}
                onUserScan={handleUserScan}
                onPrinterScan={handlePrinterScan}
                onFixedPriceScan={handleFixedPriceScan}
                onPricingEntryScan={handlePricingEntryScan}
            />
            {readyState != ReadyState.OPEN && (
                <div className="bg-yellow-500 text-center text-white">Barcode service connecting...</div>
            )}
            <Header
                darkMode
                left={<MenuPopover darkMode role={role as UserRole} />}
                right={<div className="flex justify-end pr-4 text-thriftlyOffWhite">{email}</div>}
            />
            {isInitializing ? (
                <div className="flex h-dvh w-full items-center justify-center">
                    <Loader color={orgStyles.primaryColor} />
                </div>
            ) : (
                getBody()
            )}
        </div>
    );
}
