import { AxiosError } from "axios";
import {
    Category,
    ContainerResponse,
    ErrorResponse,
    GeneratedProcessResponse,
    isSet,
    NewZebraBrowserPrint,
    PricingProcessor,
    Process,
    ProcessResponse,
    StationType,
    UserRole,
    ZebraPrinter,
} from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useWebSocket from "react-use-websocket";
import BarcodeListener from "../../components/BarcodeListener";
import { Button } from "../../components/common/Button";
import { ApiContext } from "../../components/context/ApiContext";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import Itembadge from "../../components/header/ItemBadge";
import MenuPopover from "../../components/header/MenuPopover";
import ImageModal from "../../components/ImageModal";
import { ROUTES } from "../../constants";
import { getLocalSettings, getPriceAppConfig } from "../../utils/localStorage";
import { canPrint } from "../../utils/printers";
import AiAssistantWidget from "./Components/AiAssistantWidget";
import CameraModal from "./Components/CameraModal";
import ComparableSelectorDesktop from "./Components/ComparableSelectorDesktop";
import ComparableSelectorMobile from "./Components/ComparableSelectorMobile";
import ImageCarousel from "./Components/ImageCarousel";
import InputFields from "./Components/InputFields";
import ScanInstructions from "./Components/ScanInstructions";
import WarningToast from "./Components/WarningToast";

export default function ProcessPage({
    process: processResponse,
    categories,
}: {
    process: Process | undefined;
    categories: Category[];
}) {
    const { VITE_API_BASE_URL } = import.meta.env;
    const navigate = useNavigate();
    const { role, email } = useContext(OrgContext);
    const { processes: processApi, containers: containersApi } = useContext(ApiContext);
    const { lastMessage } = useWebSocket("ws://localhost:8089", {
        shouldReconnect: () => true, // Always try to reconnect
        reconnectAttempts: 100000, // Always retry
        reconnectInterval: 3 * 1000, // Reconnect attempt interval in milliseconds
    });

    const [process, setProcess] = useState<Process>({ ...processResponse });
    const [processDraft, setProcessDraft] = useState<Process>({ ...process });
    const [generatedProcess, setGeneratedProcess] = useState<Process>();
    const [timeSaved, setTimeSaved] = useState<number>(0);
    const [itemsCount, setItemsCount] = useState<number>();

    const modalStateRef = useRef<undefined | "editProcess" | "priceLabel" | "containerLabel" | "createContainerLabel">(
        (function () {
            if (process.stationType === StationType.StationTypeSoftline) return "editProcess";
            if (!process.comparables || process.comparables?.length === 0) return "editProcess";
            return undefined;
        })()
    );
    const [modalState, setModalState] = useState<
        undefined | "editProcess" | "priceLabel" | "containerLabel" | "createContainerLabel"
    >(modalStateRef.current);
    const [isGenerating, setIsGenerating] = useState(false);

    const [selectedImage, setSelectedImage] = useState<string>();
    const [showImageModal, setShowImageModal] = useState(false);
    const [showCameraModal, setShowCameraModal] = useState(false);
    const mobileCameraRef = useRef<HTMLInputElement>(null);
    const [isUploadingImage, setIsUploadingImage] = useState(false);

    const successBeep = new Audio("/beep-success.mp3");
    const errorBeep = new Audio("/beep-error.mp3");
    const printerLoaded = useRef(false);
    const selectedPrinter = useRef<ZebraPrinter>();
    const initializedRef = useRef(false);

    useEffect(() => {
        modalStateRef.current = modalState;
    }, [modalState]);

    // get default label printer
    useEffect(() => {
        if (!canPrint() || printerLoaded.current) return;
        printerLoaded.current = true;

        NewZebraBrowserPrint(getLocalSettings().labelPrinterServer)
            .getDefaultPrinter()
            .then((printer) => (selectedPrinter.current = printer))
            .catch((error) => toast(`Error getting default printer: ${error}`));
    }, []);

    const getItemsCount = () => {
        if (role === UserRole.RolePricer) {
            processApi
                ?.itemsTodayList()
                .then((resp) => {
                    setItemsCount(resp.data.itemsToday);
                })
                .catch((err: AxiosError) => {
                    toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                });
        } else {
            processApi
                ?.itemsRemainingList()
                .then((resp) => {
                    setItemsCount(resp.data.itemsRemaining);
                })
                .catch((err: AxiosError) => {
                    toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                });
        }
    };

    useEffect(() => {
        if (initializedRef.current) return;
        initializedRef.current = true;

        getItemsCount();

        // Show no comparables toast on mobile
        if (
            process.stationType === StationType.StationTypeHardline &&
            (!process.comparables || process.comparables?.length === 0) &&
            isMobile
        ) {
            toast("No comparables found");
        }
    }, []);

    const saveProcess = async () => {
        if (JSON.stringify(processDraft) === JSON.stringify(process)) return;
        await processApi
            ?.processesUpdate(processDraft.id!, processDraft)
            .then(({ data }: { data: ProcessResponse }) => {
                setProcess(data.process!);
                setProcessDraft(data.process!);
                toast("Item updated.");
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    const deleteImage = (image: string) => {
        const updateProcess = {
            ...processDraft,
            images: processDraft.images?.filter((i) => i !== image),
        };
        processApi
            ?.processesUpdate(processDraft.id!, updateProcess)
            .then(({ data }: { data: ProcessResponse }) => {
                setProcessDraft(data.process!);
                toast("Image deleted.");
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    const printPriceLabel = () => {
        if (!canPrint()) return;

        if (selectedPrinter.current === undefined) {
            toast("Error: No printer selected");
            return;
        }

        const url = `${VITE_API_BASE_URL}/processes/${process?.id}/label?print=true`;
        selectedPrinter.current.printZPL(url).catch((error) => {
            toast(`Error printing label: ${error}`);
            console.error(`Error printing label: ${error}`);
        });
    };

    const printContainerLabel = (name: string) => {
        containersApi
            ?.containersCreate({ name })
            .then(({ data }: { data: ContainerResponse }) => {
                toast("Container created.");
                setModalState("containerLabel");

                if (selectedPrinter.current === undefined) {
                    toast("Error: No printer selected");
                    return;
                }

                const url = `${VITE_API_BASE_URL}/containers/${data.container?.id}/label?print=true`;
                selectedPrinter.current.printZPL(url).catch((error) => {
                    toast(`Error printing label: ${error}`);
                    console.error(`Error printing label: ${error}`);
                });
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    const selectImage = (image: string | undefined) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const onGenerate = () => {
        if (isGenerating) return;
        setIsGenerating(true);

        // Only pass through processor if it is not none
        const processor =
            getLocalSettings().aiAssistantImageCount !== PricingProcessor.ProcessorNone
                ? getLocalSettings().aiGenerateProcessor
                : undefined;

        processApi
            ?.generateCreate(processDraft.id!, { processor: processor })
            .then(({ data }: { data: GeneratedProcessResponse }) => {
                setProcess(processDraft); // save a copy of draft changes
                setGeneratedProcess(data.process);
                setProcessDraft({ ...processDraft, ...data.process });
                setTimeSaved(data.timeSaved || 0);
                toast("Generated AI assisted data.");
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            })
            .finally(() => {
                setIsGenerating(false);
            });
    };

    const getBody = () => {
        if (showCameraModal) {
            return (
                <div className="flex h-full flex-col items-center border-t-2 border-thriftlyGreyDark">
                    {(!processDraft?.comparables || processDraft?.comparables?.length === 0) &&
                        processDraft?.images?.length === 0 && (
                            <div className="flex flex-col gap-2 px-4 py-2 text-white">
                                No comparables found. Take a picture of the label
                            </div>
                        )}
                    <CameraModal
                        process={processDraft!}
                        onHide={(updatedProcess) => {
                            setProcessDraft({
                                ...processDraft,
                                images: updatedProcess.images,
                            });
                            setShowCameraModal(false);
                            if (
                                (updatedProcess.images?.length || 0) >= Number(getLocalSettings().aiAssistantImageCount)
                            ) {
                                onGenerate();
                            }
                        }}
                    />
                </div>
            );
        }

        if (modalState === undefined && process.stationType === StationType.StationTypeHardline && isMobile) {
            return (
                <>
                    <ComparableSelectorMobile
                        comparables={process?.comparables || []}
                        onSelectComparable={(c) => {
                            setProcessDraft({
                                ...processDraft,
                                title: c.title,
                                selectedComparable: c,
                            });
                            setModalState("editProcess");
                        }}
                        onSelectImage={selectImage}
                        onTakePicture={() => setShowCameraModal(true)}
                    />
                    {showImageModal && <ImageModal image={selectedImage} onHide={() => setShowImageModal(false)} />}
                </>
            );
        }

        return (
            <>
                <div className={`flex h-full flex-row justify-center border-t-2 border-thriftlyGreyDark pb-[64px]`}>
                    {(modalState === undefined || modalState === "editProcess") &&
                        process.stationType === StationType.StationTypeHardline &&
                        !isMobile &&
                        process.comparables &&
                        process.comparables.length !== 0 && (
                            <div className={modalState === undefined ? "w-full" : "w-1/3"}>
                                <ComparableSelectorDesktop
                                    comparables={process?.comparables || []}
                                    selectedComparable={processDraft?.selectedComparable}
                                    onSelectComparable={(c) => {
                                        setProcessDraft({
                                            ...processDraft,
                                            title: c.title,
                                            selectedComparable: c,
                                        });
                                        setModalState("editProcess");
                                    }}
                                    onSelectImage={selectImage}
                                    onTakePicture={() => setShowCameraModal(true)}
                                />
                            </div>
                        )}
                    {modalState !== undefined && (
                        <div className={`flex shrink-0 flex-col gap-8 px-4 pt-4 ${isMobile ? "w-full" : "w-2/3"}`}>
                            <div className="flex h-full w-full flex-col gap-4">
                                <ImageCarousel
                                    images={processDraft?.images || []}
                                    onSelectImageClick={selectImage}
                                    onDeleteImageClick={deleteImage}
                                    onAddPhotoClick={() => {
                                        if (isMobile) {
                                            mobileCameraRef.current?.click();
                                            return;
                                        } else {
                                            setShowCameraModal(true);
                                        }
                                    }}
                                    isUploadingImage={isUploadingImage}
                                />
                                <input
                                    ref={mobileCameraRef}
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={(e) => {
                                        if (e.target.files === null) {
                                            alert("No file selected");
                                            return;
                                        }

                                        // upload image
                                        setIsUploadingImage(true);
                                        processApi
                                            ?.imageCreate(process.id!, {
                                                image: new File([e.target.files[0]], "image", {
                                                    type: "image/png",
                                                }),
                                            })
                                            .then(({ data }: { data: ProcessResponse }) => {
                                                setProcessDraft({
                                                    ...processDraft,
                                                    images: data.process?.images,
                                                });
                                                toast("Image uploaded.");
                                                if (
                                                    (data?.process?.images?.length || 0) >=
                                                    Number(getLocalSettings().aiAssistantImageCount)
                                                ) {
                                                    onGenerate();
                                                }
                                            })
                                            .catch((err: AxiosError) => {
                                                toast(
                                                    (err.response?.data as ErrorResponse)?.error ||
                                                        "An error occurred. Please try again."
                                                );
                                            })
                                            .finally(() => {
                                                setIsUploadingImage(false);
                                            });
                                    }}
                                />
                                <div className="overflow-y-auto overscroll-y-auto">
                                    <InputFields
                                        process={process}
                                        processDraft={processDraft}
                                        onUpdateDraft={setProcessDraft}
                                        generatedProcess={generatedProcess}
                                        onUpdateGenerated={setGeneratedProcess}
                                        categories={categories}
                                    />
                                </div>
                                <div className="mt-auto flex flex-col gap-4 pb-4">
                                    <AiAssistantWidget
                                        progress={Math.min(
                                            100,
                                            (100 / Number(getLocalSettings().aiAssistantImageCount)) *
                                                (processDraft.images?.length || 0)
                                        )}
                                        onGenerate={onGenerate}
                                        isWorking={isGenerating}
                                        timeSaved={timeSaved}
                                    />
                                    <div className="flex flex-row justify-between gap-4">
                                        {modalState === "editProcess" && getPriceAppConfig().keepInStore && (
                                            <Button
                                                textColor="text-thriftlyOffWhite"
                                                backgroundColor="bg-thriftlyGreyDark"
                                                className={`w-full border-thriftlyGrey ${isMobile ? "" : "h-32 text-2xl"}`}
                                                onClick={async () => {
                                                    if (JSON.stringify(processDraft) !== JSON.stringify(process)) {
                                                        process.keepInStore = true;
                                                        await saveProcess();
                                                    }
                                                    navigate(ROUTES.PRICER_PAGE);
                                                }}
                                            >
                                                Keep in store
                                            </Button>
                                        )}
                                        {modalState === "editProcess" &&
                                            process.stationType === StationType.StationTypeHardline &&
                                            process.comparables &&
                                            process.comparables?.length !== 0 && (
                                                <Button
                                                    textColor="text-thriftlyOffWhite"
                                                    backgroundColor="bg-thriftlyGreyDark"
                                                    className={`w-full border-thriftlyGrey ${isMobile ? "" : "h-32 text-2xl"}`}
                                                    onClick={async () => {
                                                        setProcessDraft({
                                                            ...processDraft,
                                                            selectedComparable: undefined,
                                                        });
                                                        setModalState(undefined);
                                                    }}
                                                >
                                                    Back
                                                </Button>
                                            )}
                                        {modalState === "editProcess" && (
                                            <Button
                                                textColor="text-thriftlyGreyDark"
                                                backgroundColor="bg-thriftlyOffWhite"
                                                className={`w-full border-thriftlyGrey ${isMobile ? "" : "h-32 text-2xl"}`}
                                                onClick={async () => {
                                                    if (!isSet(processDraft.title) || !isSet(processDraft?.price)) {
                                                        return;
                                                    }
                                                    if (JSON.stringify(processDraft) !== JSON.stringify(process)) {
                                                        await saveProcess();
                                                    }

                                                    // Print label, if able
                                                    if (canPrint()) printPriceLabel();

                                                    // If the user is on mobile or can't print, skip the scan instructions
                                                    if (!isMobile && canPrint()) {
                                                        setModalState("priceLabel");
                                                    } else {
                                                        navigate(ROUTES.PRICER_PAGE, {
                                                            state: { lastPriceLabelPrinted: process.id },
                                                        });
                                                    }
                                                }}
                                                disabled={
                                                    !isSet(processDraft.title) ||
                                                    !isSet(processDraft?.price) ||
                                                    processDraft?.price === "$"
                                                }
                                            >
                                                {canPrint() ? "Continue" : "Done"}
                                            </Button>
                                        )}
                                        {(modalState === "priceLabel" || modalState === "containerLabel") &&
                                            JSON.stringify(processDraft) !== JSON.stringify(process) && (
                                                <Button
                                                    textColor="text-thriftlyOffWhite"
                                                    backgroundColor="bg-thriftlyGreyDark"
                                                    className={`h-32 w-full border-thriftlyGrey text-2xl`}
                                                    onClick={() => {
                                                        setProcessDraft(process);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                        {modalState !== "editProcess" && (
                                            <Button
                                                textColor="text-thriftlyGreyDark"
                                                backgroundColor="bg-thriftlyOffWhite"
                                                className={`h-32 w-full border-thriftlyGrey text-2xl`}
                                                disabled={JSON.stringify(processDraft) === JSON.stringify(process)}
                                                onClick={async () => {
                                                    await saveProcess();
                                                    if (
                                                        processDraft.price != process.price ||
                                                        processDraft.size != process.size ||
                                                        processDraft.category != process.category
                                                    ) {
                                                        printPriceLabel();
                                                        setModalState("priceLabel");
                                                    }
                                                }}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </div>
                                    {modalState == "editProcess" &&
                                        process.processor === PricingProcessor.ProcessorSerpApi &&
                                        (!process.comparables || process.comparables?.length === 0) &&
                                        !isMobile && <WarningToast text="No comparables found" closeable />}
                                    {modalState !== "editProcess" &&
                                        JSON.stringify(processDraft) !== JSON.stringify(process) && (
                                            <WarningToast text="Unsaved changes" />
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
                    {modalState !== undefined && modalState !== "editProcess" && (
                        <div className="w-1/3">
                            <ScanInstructions
                                modalState={modalState}
                                setModalState={setModalState}
                                printerName={selectedPrinter.current?.info.name}
                                unsavedChanges={JSON.stringify(processDraft) !== JSON.stringify(process)}
                                onPrintPriceLabel={printPriceLabel}
                                onPrintContainerLabel={printContainerLabel}
                            />
                        </div>
                    )}
                </div>
                {showImageModal && <ImageModal image={selectedImage} onHide={() => setShowImageModal(false)} />}
            </>
        );
    };

    return (
        <div className="flex h-dvh flex-col overflow-y-clip overscroll-none">
            <Header
                darkMode
                left={
                    <div className="flex flex-row items-center gap-4 pl-4">
                        <MenuPopover role={role as UserRole} darkMode />
                        {!isMobile && (
                            <div className="flex h-[20px] shrink-0 items-center justify-end">
                                <div className="rounded bg-thriftlyGreyDark px-2 py-1 text-xs uppercase text-thriftlyOffWhite">
                                    {process.stationType === StationType.StationTypeHardline
                                        ? "HARD GOODS"
                                        : "SOFT GOODS"}
                                </div>
                            </div>
                        )}
                    </div>
                }
                right={
                    <div className="flex flex-row items-center justify-end gap-4 pr-4">
                        {!isMobile && <div className="text-thriftlyOffWhite">{email}</div>}
                        <Itembadge role={role as UserRole} itemsCount={itemsCount} />
                    </div>
                }
            />
            <BarcodeListener
                barcodeData={lastMessage?.data}
                onPriceLabel={(scannerId, barcode) => {
                    if (
                        modalStateRef.current === "priceLabel" &&
                        JSON.stringify(processDraft) !== JSON.stringify(process)
                    ) {
                        toast("Save changes before proceeding.");
                        errorBeep.play();
                    } else if (
                        (modalStateRef.current === "editProcess" || modalStateRef.current === "priceLabel") &&
                        barcode === processDraft?.id
                    ) {
                        successBeep.play();
                        setModalState("containerLabel");
                    } else if (modalStateRef.current === "priceLabel") {
                        errorBeep.play();
                        toast("Invalid barcode. Please try again.");
                    } else if (modalStateRef.current === "containerLabel") {
                        toast("Price label already scanned. Scan a container label.");
                        errorBeep.play();
                    }
                }}
                onContainerLabel={(scannerId, barcode) => {
                    if (modalStateRef.current === "containerLabel") {
                        containersApi
                            ?.placeCreate(barcode, {
                                processIds: [process.id!],
                            })
                            .then(() => {
                                toast("Item added to inventory");
                                successBeep.play();
                                navigate(ROUTES.PRICER_PAGE);
                            })
                            .catch(() => {
                                toast("An error occurred. Please try again.");
                                errorBeep.play();
                            });
                        successBeep.play();
                    } else if (modalStateRef.current === "priceLabel") {
                        toast("Price label not scanned. Scan a price label first.");
                        errorBeep.play();
                    }
                }}
            />
            {getBody()}
        </div>
    );
}
