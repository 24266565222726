import { AxiosError } from "axios";
import { ErrorResponse, StationType, UserRole } from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { ApiContext } from "../../components/context/ApiContext";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import Itembadge from "../../components/header/ItemBadge";
import MenuPopover from "../../components/header/MenuPopover";
import {
    getLocalSettings,
    getOfflineItems,
    setLabelLogo,
    setLocalSettings,
    setOfflineItems,
    setPriceAppConfig,
    setPricingEntries,
    setTaxonomies,
    setTopLevelTaxonomies,
} from "../../utils/localStorage";
import OfflinePricing, { OfflinePricingRef } from "./OfflinePricing";
import OneClickPricing from "./OneClickPricing";
import RapidPricing from "./RapidPricing";

export default function PricerPage() {
    const { VITE_API_WEBSOCKET_URL } = import.meta.env;
    const { orgId, role, email } = useContext(OrgContext);
    const {
        processes: processApi,
        organizations: organizationsApi,
        pricing: pricingApi,
        taxonomies: taxonomiesApi,
    } = useContext(ApiContext);
    const [stationType, setStationType] = useState(getLocalSettings().stationType);
    const {
        lastMessage: barcodeLastMessage,
        sendJsonMessage: barcodeSendJsonMessage,
        readyState: barcodeReadyState,
    } = useWebSocket("ws://localhost:8089", {
        shouldReconnect: () => true, // Always try to reconnect
        reconnectAttempts: 100000, // Always retry
        reconnectInterval: 3 * 1000, // Reconnect attempt interval in milliseconds
    });
    const {
        lastMessage: apiLastMessage,
        sendJsonMessage: apiSendJsonMessage,
        readyState: apiReadyState,
    } = useWebSocket(VITE_API_WEBSOCKET_URL, {
        shouldReconnect: () => true, // Always try to reconnect
        reconnectAttempts: 100000, // Always retry
        reconnectInterval: 3 * 1000, // Reconnect attempt interval in milliseconds
        onOpen: () => {
            uploadOfflineItems();
        },
    });

    const [itemsCount, setItemsCount] = useState<number>();
    const [offlineMode, setOfflineMode] = useState(false);
    const [offlinePricingActive, setOfflinePricingActive] = useState(false);
    const offlinePricingRef = useRef<OfflinePricingRef>(null);

    const getItemsCount = () => {
        processApi
            ?.itemsTodayList()
            .then((resp) => {
                setItemsCount(resp.data.itemsToday);
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    const getBody = () => {
        // Handle offline mode
        if (offlineMode) {
            return (
                <OfflinePricing
                    lastMessage={barcodeLastMessage}
                    setItemsCount={setItemsCount}
                    setOfflinePricingActive={setOfflinePricingActive}
                />
            );
        }

        // one-line pricing if the barcode agent is available
        if (barcodeReadyState === ReadyState.OPEN) {
            return (
                <OneClickPricing
                    lastMessage={barcodeLastMessage}
                    sendJsonMessage={barcodeSendJsonMessage}
                    getItemsCount={getItemsCount}
                />
            );
        }

        // otherwise, rapid pricing
        return <RapidPricing getItemsCount={getItemsCount} />;
    };

    // Cache information necessary for the pricer page and offline production
    useEffect(() => {
        // cache the pricing app config
        organizationsApi?.priceAppConfigDetail(orgId).then((response) => {
            if (response.data.priceAppConfig) {
                setPriceAppConfig(response.data.priceAppConfig);
                setLabelLogo(response.data.logo);
            }
        });

        // cache the pricing entries
        pricingApi?.pricingList().then((response) => {
            if (response.data.pricingEntries) setPricingEntries(response.data.pricingEntries);
        });

        // cache the taxonomy categories
        taxonomiesApi?.taxonomiesList().then((response) => {
            if (response.data.taxonomies) setTaxonomies(response.data.taxonomies);
            if (response.data.topLevelTaxonomies) setTopLevelTaxonomies(response.data.topLevelTaxonomies);
        });

        // cache images used by offline pricing component
        const images = ["/barcode_scanner_qr_code.svg"];
        images.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    }, []);

    // Handle api websocket keep-alive and determine offline mode
    useEffect(() => {
        if (apiReadyState === ReadyState.OPEN && apiLastMessage?.data === "ping") {
            apiSendJsonMessage("pong");
        }
    }, [apiLastMessage]);
    useEffect(() => {
        if (apiReadyState === ReadyState.CLOSING || apiReadyState === ReadyState.CLOSED) {
            setOfflineMode(true);
        } else if (apiReadyState === ReadyState.OPEN && !offlinePricingActive) {
            setOfflineMode(false);
        }
    }, [apiReadyState, offlinePricingActive]);

    const uploadOfflineItems = () => {
        if (getOfflineItems().length == 0 || offlinePricingRef.current?.isActive()) {
            return;
        }

        processApi
            ?.uploadCreate({ processes: getOfflineItems() })
            .then(() => {
                toast("Offline items uploaded successfully");
                setOfflineItems([]);
            })
            .catch(() => {
                toast("Error uploading items priced offline");
            });
    };

    return (
        <>
            <div className="flex h-dvh flex-col overflow-y-clip overscroll-none">
                {offlineMode && <div className="bg-yellow-500 text-center text-white">Offline production mode</div>}
                <Header
                    darkMode
                    left={
                        <div className="flex flex-row items-center gap-4 pl-4">
                            <MenuPopover role={role as UserRole} darkMode />
                            {!isMobile && (
                                <div className="flex h-[20px] shrink-0 items-center justify-end">
                                    <div
                                        className="cursor-pointer rounded bg-thriftlyGreyDark px-2 py-1 text-xs uppercase text-thriftlyOffWhite"
                                        onClick={() => {
                                            const newStationType =
                                                stationType === StationType.StationTypeHardline
                                                    ? StationType.StationTypeSoftline
                                                    : StationType.StationTypeHardline;
                                            setStationType(newStationType);
                                            setLocalSettings({ ...getLocalSettings(), stationType: newStationType });
                                        }}
                                    >
                                        {stationType === StationType.StationTypeHardline ? "HARD GOODS" : "SOFT GOODS"}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    right={
                        <div className="flex flex-row items-center justify-end gap-4 pr-4">
                            {!isMobile && <div className="text-thriftlyOffWhite">{email}</div>}
                            <Itembadge role={role as UserRole} itemsCount={itemsCount} />
                        </div>
                    }
                />
                <div className="flex h-full w-full flex-col items-center justify-center border-t-2 border-thriftlyGreyDark">
                    {getBody()}
                </div>
            </div>
        </>
    );
}
