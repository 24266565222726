import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import {
    capitalize,
    ErrorResponse,
    isSet,
    Location,
    Locations,
    LocationsResponse,
    LocationStyles,
    LoginResponse,
    Organization,
    Organizations,
    OrganizationsResponse,
    OrganizationStylesResponse,
    TOKEN_KEY,
    UserRole,
    validateEmail,
} from "common";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomDropdown from "../components/common/CustomDropdown";
import CustomInputText from "../components/common/CustomInputText";
import Loader from "../components/common/Loader";
import { StyledButton } from "../components/common/StyledButton";
import { ApiContext } from "../components/context/ApiContext";
import { OrgContext } from "../components/context/OrgContext";
import { ROUTES, SESSION_KEY } from "../constants";
import { getOrgStyles as getOrgStylesLocalStorage, setOrgStyles as setOrgStylesLocal } from "../utils/localStorage";
import { getInfoFromSessionToken, getInfoFromToken } from "../utils/token";

function LoginPage() {
    const { VITE_API_BASE_URL } = import.meta.env;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setOrgContext } = useContext(OrgContext);
    const { login: loginApi, organizations: organizationsApi } = useContext(ApiContext);

    const [isLoadingOrgStyles, setIsLoadingOrgStyles] = useState<boolean>(true);
    const [orgStyles, setOrgStyles] = useState<LocationStyles>(getOrgStylesLocalStorage());

    const [emailAddress, setEmailAddress] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<string>();
    const [showSessionFrom, setShowSessionFrom] = useState<boolean>(false);

    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [orgId, setOrgId] = useState<string | undefined>(undefined);
    const [role, setRole] = useState<UserRole | undefined>(undefined);
    const [locations, setLocations] = useState<Location[]>([]);
    const [locationId, setLocationId] = useState<string | undefined>(undefined);

    const valid = () => {
        if (showSessionFrom) {
            if (getInfoFromToken()?.role === UserRole.RoleSystem) {
                return isSet(orgId) && isSet(role) && (role === UserRole.RolePricer ? isSet(locationId) : true);
            }
            return isSet(role) && (role === UserRole.RolePricer ? isSet(locationId) : true);
        }

        return validateEmail(emailAddress) && isSet(password);
    };

    const loadSessionOptions = () => {
        if (getInfoFromToken()?.role === UserRole.RoleSystem) {
            // must instantiate new because setOrgContext hasn't resolved
            new Organizations({
                baseURL: `${VITE_API_BASE_URL}`,
                headers: {
                    Authorization: `Bearer ${getInfoFromToken()?.token}`,
                },
            })
                .organizationsList()
                .then(({ data }: { data: OrganizationsResponse }) => setOrganizations(data.organizations!))
                .catch((error: AxiosError) =>
                    toast(`Error getting organizations: ${(error.response?.data as ErrorResponse).error}`)
                );
        } else {
            setOrgId(getInfoFromToken()?.orgId);
        }

        // must instantiate new because setOrgContext hasn't resolved
        new Locations({
            baseURL: `${VITE_API_BASE_URL}`,
            headers: {
                Authorization: `Bearer ${getInfoFromToken()?.token}`,
            },
        })
            .locationsList()
            .then(({ data }: { data: LocationsResponse }) => {
                if (data.locations && data.locations.length > 0) {
                    setLocations(data.locations);
                }
            })
            .catch((error: AxiosError) =>
                toast(`Error getting locations: ${(error.response?.data as ErrorResponse).error}`)
            );

        setShowSessionFrom(true);
    };

    const loggedIn = () => {
        Sentry.setContext("user", {
            id: getInfoFromToken()?.userId,
            email: getInfoFromToken()?.email,
            orgId: getInfoFromToken()?.orgId,
            role: getInfoFromToken()?.role,
        });
        switch (getInfoFromSessionToken()?.role || getInfoFromToken()?.role) {
            case UserRole.RolePricer:
                navigate(ROUTES.PRICER_PAGE);
                break;
            case UserRole.RoleLister:
                navigate(ROUTES.LISTER_PAGE);
                break;
            case UserRole.RoleAdmin:
                navigate(ROUTES.PRODUCTION_PAGE);
                break;
            default:
                loadSessionOptions();
        }
    };

    const handleLogin = () => {
        if (!valid()) return;
        setLoginError("");
        setIsLoading(true);

        loginApi
            ?.loginCreate({ email: emailAddress, password: password })
            .then(({ data }: { data: LoginResponse }) => {
                if (data.token) {
                    localStorage.setItem(TOKEN_KEY, data.token);
                    setOrgContext();
                    loggedIn();
                } else {
                    setLoginError("An error occurred. Please try again.");
                    setIsLoading(false);
                }
            })
            .catch((err: AxiosError) => {
                setLoginError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSessionLogin = () => {
        loginApi
            ?.sessionCreate({ orgId, role, locationId })
            .then(({ data }: { data: LoginResponse }) => {
                localStorage.setItem(SESSION_KEY, data.token!);
                setOrgContext();
                let message = "Updated ";
                if (orgId !== getInfoFromToken()?.orgId) {
                    message += ` organization to ${organizations.find((org: Organization) => org.id === orgId)?.name} and `;
                }
                message += ` role to ${capitalize(role!)}`;
                if (locationId) {
                    message += ` at location ${locations.find((location: Location) => location.id === locationId)?.name}`;
                }
                toast(message);
                loggedIn();
            })
            .catch((error: AxiosError) =>
                toast(`Error creating new session: ${(error.response?.data as ErrorResponse).error}`)
            );
    };

    // handle logging in with an api key
    useEffect(() => {
        const apiKey = searchParams.get("apiKey");
        if (apiKey === null) {
            return;
        }

        setIsLoading(true);
        loginApi
            ?.loginCreate({ apiKey })
            .then(({ data }: { data: LoginResponse }) => {
                if (data.token) {
                    localStorage.setItem(TOKEN_KEY, data.token);
                    setOrgContext();
                    loggedIn();
                } else {
                    setLoginError("An error occurred. Please try again.");
                }
            })
            .catch((err: AxiosError) => {
                setLoginError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const getOrgIdFromURL = () => {
        const hostname = window.location.hostname.replace("-staging", "");
        const split = hostname.split(".");
        if (split[0] === "pricing") {
            return undefined;
        }
        return split.length > 1 ? split[0] : undefined;
    };

    // handle loading org styles
    useEffect(() => {
        const orgId = getOrgIdFromURL();
        if (!orgId) {
            // TODO: Look up org by domain instead of thriftly subdomain
            setIsLoadingOrgStyles(false);
            return;
        }

        organizationsApi
            ?.stylesDetail(orgId)
            .then(({ data }: { data: OrganizationStylesResponse }) => {
                if (data.styles) {
                    setOrgStyles(data.styles);
                    setOrgStylesLocal(data.styles);
                }
            })
            .catch((error: AxiosError) => {
                console.error("Error fetching org styles: ", error);
            })
            .finally(() => {
                setIsLoadingOrgStyles(false);
            });
    }, []);

    if (isLoadingOrgStyles) {
        return (
            <div className="flex h-dvh flex-col items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="flex h-dvh items-center justify-center">
            <div className="flex w-[500px] flex-col items-center gap-1 rounded-lg border border-solid bg-thriftlyOffWhite p-10">
                <img width="200px" src={orgStyles.logo} alt="logo" />
                <h1 className="my-4 text-xl">Log in</h1>

                {!showSessionFrom ? (
                    <>
                        <CustomInputText
                            className="w-full"
                            type="email"
                            label="Email address"
                            placeholder="Email address"
                            value={emailAddress}
                            onChange={setEmailAddress}
                        />
                        <CustomInputText
                            className="w-full"
                            type="password"
                            label="Password"
                            placeholder="Password"
                            value={password}
                            onChange={setPassword}
                            onEnter={handleLogin}
                        />
                    </>
                ) : (
                    <>
                        <CustomDropdown
                            label="Organization"
                            placeholder="Select organization"
                            onChange={setOrgId}
                            value={orgId || ""}
                            options={organizations.map((org: Organization) => {
                                return { label: org.name, value: org.id };
                            })}
                        />
                        <CustomDropdown
                            label="Role"
                            placeholder="Select role"
                            onChange={(value) => setRole(value as UserRole)}
                            value={role || ""}
                            options={Object.values(UserRole)
                                .filter((role: UserRole) => role !== UserRole.RoleSystem)
                                .map((role) => {
                                    return {
                                        label: capitalize(role),
                                        value: role,
                                    };
                                })}
                        />
                        {role === UserRole.RolePricer && (
                            <CustomDropdown
                                label="Location"
                                placeholder="Select location"
                                onChange={setLocationId}
                                value={locationId || ""}
                                options={locations
                                    .filter((location: Location) => orgId === undefined || location.orgId === orgId)
                                    .map((location: Location) => {
                                        return {
                                            label: location.name!,
                                            value: location.id!,
                                        };
                                    })}
                            />
                        )}
                    </>
                )}

                <StyledButton
                    backgroundColor={orgStyles.primaryColor}
                    disabled={!valid()}
                    className="w-full"
                    onClick={() => (showSessionFrom ? handleSessionLogin() : handleLogin())}
                >
                    Log in
                </StyledButton>

                {isLoading && (
                    <div className="mt-4">
                        <Loader />
                    </div>
                )}
                {loginError && <p className="mt-4 text-red-500">{loginError}</p>}

                <hr className="my-4 h-px w-full bg-thriftlyForest" />

                <a href={ROUTES.FORGOT_PASSWORD} className="text-thriftlyForest">
                    I forgot my password
                </a>
            </div>
        </div>
    );
}

export default LoginPage;
