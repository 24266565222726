import { AxiosError } from "axios";
import { ErrorResponse, isSet, NewZebraBrowserPrint, PricingProcessor, StationType, ZebraPrinter } from "common";
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { MdRefresh } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BarcodeListener from "../../components/BarcodeListener";
import { Button } from "../../components/common/Button";
import Camera, { CameraRef } from "../../components/common/Camera";
import Loader from "../../components/common/Loader";
import { ApiContext } from "../../components/context/ApiContext";
import { ROUTES } from "../../constants";
import { convertUserImage } from "../../utils/camera";
import { getLocalSettings } from "../../utils/localStorage";
import { canPrint } from "../../utils/printers";

export default function RapidPricing({ getItemsCount }: { getItemsCount: () => void }) {
    const { VITE_API_BASE_URL } = import.meta.env;
    const navigate = useNavigate();
    const { processes: processApi } = useContext(ApiContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    const printerLoaded = useRef(false);
    const selectedPrinter = useRef<ZebraPrinter>();
    const { state } = useLocation();
    const [lastPriceLabelPrinted, setLastPriceLabelPrinted] = useState<string | undefined>(
        state ? state.lastPriceLabelPrinted : undefined
    );

    const cameraRef = useRef<CameraRef>(null);
    const mobileCameraRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getItemsCount();

        // get default label printer
        if (canPrint() && !printerLoaded.current) {
            printerLoaded.current = true;

            NewZebraBrowserPrint(getLocalSettings().labelPrinterServer)
                .getDefaultPrinter()
                .then((printer) => (selectedPrinter.current = printer))
                .catch((error) => toast(`Error getting default printer: ${error}`));
        }

        // open camera automtically on mobile when page loads
        if (isMobile) mobileCameraRef?.current?.click();
    }, []);

    const getProcessor = (price?: string) => {
        if (isSet(price)) return PricingProcessor.ProcessorNone;

        // comparables for hard line, user configured for soft line
        if (getLocalSettings().stationType === StationType.StationTypeHardline) {
            return PricingProcessor.ProcessorSerpApi;
        } else if (getLocalSettings().stationType === StationType.StationTypeSoftline) {
            return getLocalSettings().aiGenerateProcessor;
        }

        return PricingProcessor.ProcessorNone;
    };

    const getBody = () => {
        if (isLoading) {
            return (
                <div className="w-full">
                    <Loader />
                </div>
            );
        }

        if (isMobile) {
            return (
                <div className="flex w-full flex-col gap-4 p-8">
                    <Button
                        textColor="text-thriftlyGreyDark"
                        backgroundColor="bg-thriftlyOffWhite"
                        className={`h-32 w-full border-thriftlyGrey text-2xl`}
                        onClick={() => {
                            mobileCameraRef?.current?.click();
                        }}
                    >
                        Take picture
                    </Button>
                    {error && <p className="text-md text-center text-red-500">{error}</p>}
                    <input
                        ref={mobileCameraRef}
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        capture="environment"
                        onChange={(e) => {
                            if (e.target.files === null) {
                                alert("No file selected");
                                return;
                            }

                            // upload image
                            setIsLoading(true);
                            processApi
                                ?.processesCreate(
                                    {
                                        image: new File([e.target.files[0]], "image", { type: "image/png" }),
                                    },
                                    {
                                        processor: getProcessor(),
                                        stationType: getLocalSettings().stationType,
                                        skipInventory: true,
                                    }
                                )
                                .then(async (resp) => {
                                    navigate(`${ROUTES.PROCESS_PAGE}/${resp?.data?.process?.id}`);
                                })
                                .catch((err: AxiosError) => {
                                    setError(
                                        (err.response?.data as ErrorResponse)?.error ||
                                            "An error occurred. Please try again."
                                    );
                                })
                                .finally(() => {
                                    setIsLoading(false);
                                });
                        }}
                    />
                </div>
            );
        }

        if (getLocalSettings().cameraRapidPricing === "false") {
            return <div className="text-2xl text-thriftlyOffWhite">Scan a barcode to continue</div>;
        }

        return (
            <Camera
                ref={cameraRef}
                onImageCapture={async (
                    userImage: string,
                    setCameraIsLoading: (isLoading: boolean) => void,
                    closeCamera: () => void,
                    clearImage: () => void,
                    price?: string,
                    condition?: string,
                    category?: string,
                    gender?: string,
                    pricingEntryId?: string
                ) => {
                    setCameraIsLoading(true);
                    setIsLoading(true);
                    setError("");
                    // upload image
                    await processApi
                        ?.processesCreate(
                            {
                                image: convertUserImage(userImage),
                            },
                            {
                                price,
                                condition,
                                category,
                                gender,
                                processor: getProcessor(price),
                                stationType: getLocalSettings().stationType,
                                pricingEntryId,
                                skipInventory: price === undefined && pricingEntryId === undefined ? true : undefined,
                            }
                        )
                        .then(async (resp) => {
                            setCameraIsLoading(false);
                            // when rapid pricing, print a price label immediately. Otherwise proceed to process page
                            if (
                                isSet(pricingEntryId) ||
                                (isSet(price) && resp.data.process?.processor === PricingProcessor.ProcessorNone)
                            ) {
                                clearImage();
                                getItemsCount();
                                toast.success("Item successfully priced");
                                setLastPriceLabelPrinted(resp.data.process?.id);

                                if (!canPrint() || selectedPrinter.current == undefined) return;
                                const url = `${VITE_API_BASE_URL}/processes/${resp.data.process?.id}/label?print=true`;
                                selectedPrinter.current.printZPL(url).catch((error) => {
                                    toast(`Error printing label: ${error}`);
                                    console.error(`Error printing label: ${error}`);
                                });
                            } else {
                                closeCamera();
                                navigate(`${ROUTES.PROCESS_PAGE}/${resp?.data?.process?.id}`);
                            }
                        })
                        .catch((err: AxiosError) => {
                            setError(
                                (err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again."
                            );
                        })
                        .finally(() => {
                            setCameraIsLoading(false);
                            setIsLoading(false);
                        });
                }}
            >
                <div className="flex flex-col gap-4">
                    <Button
                        textColor="text-thriftlyGreyDark"
                        backgroundColor="bg-thriftlyOffWhite"
                        className={`h-32 w-full border-thriftlyGrey text-2xl`}
                        onClick={() => {
                            cameraRef?.current?.capture();
                        }}
                    >
                        Search
                    </Button>
                    {error && <p className="text-md text-center text-red-500">{error}</p>}
                </div>
            </Camera>
        );
    };

    return (
        <>
            {getBody()}
            {lastPriceLabelPrinted && (
                <div className="absolute bottom-0 right-0 mt-auto flex flex-row p-4">
                    <Button
                        className="ml-auto mt-auto"
                        onClick={() => {
                            if (!canPrint() || selectedPrinter.current == undefined) return;
                            const url = `${VITE_API_BASE_URL}/processes/${lastPriceLabelPrinted}/label?print=true`;
                            selectedPrinter.current.printZPL(url).catch((error) => {
                                toast(`Error printing label: ${error}`);
                                console.error(`Error printing label: ${error}`);
                            });
                        }}
                    >
                        <MdRefresh />
                        Reprint label
                    </Button>
                </div>
            )}
            <BarcodeListener
                onFixedPriceScan={async (scannerId, price, condition, category, gender) => {
                    if (isLoading) return;
                    if (getLocalSettings().cameraRapidPricing === "false") {
                        setIsLoading(true);
                        // don't upload an image
                        await processApi
                            ?.processesCreate(
                                {},
                                {
                                    price,
                                    condition,
                                    category,
                                    gender,
                                    processor: getProcessor(price),
                                    stationType: getLocalSettings().stationType,
                                }
                            )
                            .then(async (resp) => {
                                getItemsCount();
                                toast.success("Item successfully priced");
                                setLastPriceLabelPrinted(resp.data.process?.id);

                                if (!canPrint() || selectedPrinter.current == undefined) return;
                                const url = `${VITE_API_BASE_URL}/processes/${resp.data.process?.id}/label?print=true`;
                                selectedPrinter.current.printZPL(url).catch((error) => {
                                    toast(`Error printing label: ${error}`);
                                    console.error(`Error printing label: ${error}`);
                                });
                            })
                            .catch((err: AxiosError) => {
                                setError(
                                    (err.response?.data as ErrorResponse)?.error ||
                                        "An error occurred. Please try again."
                                );
                            })
                            .finally(() => {
                                setIsLoading(false);
                            });
                    } else {
                        cameraRef?.current?.capture(price, condition, category, gender);
                    }
                }}
                onPricingEntryScan={async (scannerId, pricingEntryId) => {
                    if (isLoading) return;
                    if (getLocalSettings().cameraRapidPricing === "false") {
                        setIsLoading(true);
                        // don't upload an image
                        await processApi
                            ?.processesCreate(
                                {},
                                {
                                    pricingEntryId,
                                    processor: PricingProcessor.ProcessorNone,
                                    stationType: getLocalSettings().stationType,
                                }
                            )
                            .then(async (resp) => {
                                getItemsCount();
                                toast.success("Item successfully priced");
                                setLastPriceLabelPrinted(resp.data.process?.id);

                                if (!canPrint() || selectedPrinter.current == undefined) return;
                                const url = `${VITE_API_BASE_URL}/processes/${resp.data.process?.id}/label?print=true`;
                                selectedPrinter.current.printZPL(url).catch((error) => {
                                    toast(`Error printing label: ${error}`);
                                    console.error(`Error printing label: ${error}`);
                                });
                            })
                            .catch((err: AxiosError) => {
                                setError(
                                    (err.response?.data as ErrorResponse)?.error ||
                                        "An error occurred. Please try again."
                                );
                            })
                            .finally(() => {
                                setIsLoading(false);
                            });
                    } else {
                        cameraRef?.current?.capture(undefined, undefined, undefined, undefined, pricingEntryId);
                    }
                }}
            />
        </>
    );
}
