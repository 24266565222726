export const SESSION_KEY = "jwtsession";
export const RECOVERY_TOKEN_KEY = "token";
export const ORG_STYLES_KEY = "orgStyles";
export const PRICE_APP_CONFIG_KEY = "priceAppConfig";
export const PRICING_ENTRIES_KEY = "pricingEntries";
export const LABEL_LOGO_KEY = "labelLogo";
export const OFFLINE_ITEMS_KEY = "offlineItems";
export const TAXONOMIES_KEY = "taxonomies";
export const TOP_LEVEL_TAXONOMIES_KEY = "topLevelTaxonomies";
export const LOCAL_SETTINGS_KEY = "localSettings";
export const USER_SESSIONS_KEY = "userSessions";

export enum ROUTES {
    HOME = "/",
    LOGIN = "/login",
    LOGOUT = "/logout",
    FORGOT_PASSWORD = "/forgot-password",
    PASSWORD_RECOVERY = "/password-recovery",
    PRODUCTION_PAGE = "/production",
    PRICER_PAGE = "/pricer",
    PROCESS_PAGE = "/process",
    LISTER_PAGE = "/lister",
    PULL_LIST_PAGE = "/pull-list",
    PLACE_PAGE = "/place",
    CONTAINERS_PAGE = "/containers",
    SETTINGS_PAGE = "/settings",
    INVENTORY_PAGE = "/inventory",
    SHOP_PAGE = "/shop",
}
