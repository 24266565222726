import { AxiosError } from "axios";
import { Category, ErrorResponse, isSet, Process, ProcessResponse, StationType } from "common";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import { ApiContext } from "../../components/context/ApiContext";
import { ROUTES } from "../../constants";
import { getPriceAppConfig, getTopLevelTaxonomies } from "../../utils/localStorage";
import ProcessPage from "./ProcessPage";

export default function ProcessPageLoader() {
    const navigate = useNavigate();
    const params = useParams();
    const { processes: processApi, categories: categoriesApi } = useContext(ApiContext);

    const [isLoading, setIsLoading] = useState(true);
    const [process, setProcess] = useState<Process>();
    const [categories, setCategories] = useState<Category[]>([]);

    // fetch process using path param
    useEffect(() => {
        processApi
            ?.processesDetail(params.id!)
            .then(({ data: processData }: { data: ProcessResponse }) => {
                if (!processData.process) {
                    toast("An error occurred. Please try again.");
                    navigate(ROUTES.HOME);
                    return;
                }
                let processDraft = processData.process;
                if (getPriceAppConfig().descriptionTemplate && !isSet(processDraft.notes)) {
                    processDraft = {
                        ...processDraft,
                        notes: getPriceAppConfig().descriptionTemplate,
                    };
                }
                setProcess(processDraft);

                // get categories and filter them based on process station type
                categoriesApi?.categoriesList().then(({ data }) => {
                    const categories = (data.categories || []).filter(
                        (category) => category.stationType === processData.process?.stationType
                    );
                    if (categories.length === 0) {
                        if (processData.process?.stationType === StationType.StationTypeSoftline) {
                            // soft line only has apparel and accessories
                            categories.push({ name: "Apparel and Accessories", taxonomyCode: "aa" }, { name: "Uncategorized", taxonomyCode: "na" });
                        } else {
                            // hard line has all top level taxonomies
                            categories.push(
                                ...getTopLevelTaxonomies()
                                    .filter((category) => category.code != "aa")
                                    .sort((a, b) => a?.name?.localeCompare(b?.name || "") || 0)
                                    .map((category) => {
                                        return { name: category.name, taxonomyCode: category.code };
                                    })
                            );
                        }
                    } else if (categories.find((category) => category.taxonomyCode === "na") === undefined) {
                        // if there is no uncategorized category, add it
                        categories.push({ name: "Uncategorized", taxonomyCode: "na" });
                    }
                    setCategories(categories);
                });
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error);
                toast("An error occurred. Please try again.");
                navigate(ROUTES.HOME);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loader />
            </div>
        );
    }

    return <ProcessPage process={process} categories={categories} />;
}
