import { createContext } from "react";

type OrgContextType = {
    userId: string;
    orgId: string;
    authenticated: boolean;
    role: string;
    locationId: string;
    email: string;
    token: string | null;
    setOrgContext: () => void;
};

export const OrgContext = createContext<OrgContextType>({
    userId: "",
    orgId: "",
    authenticated: false,
    role: "",
    locationId: "",
    email: "",
    token: "",
    setOrgContext: () => {},
});
