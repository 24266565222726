import { isSet } from "common";
import { HTMLInputTypeAttribute, ReactNode } from "react";
import { MdRotateLeft } from "react-icons/md";

type InputProps = {
    className?: string;
    labelTextColor?: string;
    textColor?: string;
    backgroundColor?: string;
    borderColor?: string;
    disabled?: boolean;
    label?: string;
    placeholder: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    onEnter?: (value: string) => void;
    onUndo?: () => void;
    type?: HTMLInputTypeAttribute;
    originalValue?: string;
    value?: string;
    generatedValue?: string;
    allowedCharacters?: string;
    maxLength?: number;
    prefix?: ReactNode;
    suffix?: ReactNode;
};

export default function GeneratedInputText(props: InputProps) {
    const {
        className,
        labelTextColor,
        textColor,
        backgroundColor,
        borderColor,
        disabled,
        label,
        originalValue,
        value,
        generatedValue,
        placeholder,
        onChange,
        onBlur,
        onEnter,
        onUndo,
        type,
        allowedCharacters,
        maxLength,
        prefix,
        suffix,
    } = props;

    const hasGeneratedValue = isSet(generatedValue) && (generatedValue !== value || generatedValue !== originalValue);

    const getLabel = () => {
        if (label !== undefined && value !== undefined && value !== "") {
            return label;
        }

        return "";
    };

    const getPlaceholder = () => {
        if (value === undefined || value === "") {
            return placeholder;
        }

        return "";
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            if (allowedCharacters) {
                onChange(e.target.value.replace(new RegExp(`[^${allowedCharacters}]`, "g"), ""));
            } else {
                onChange(e.target.value);
            }
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
            if (allowedCharacters) {
                onBlur(e.target.value.replace(new RegExp(`[^${allowedCharacters}]`, "g"), ""));
            } else {
                onBlur(e.target.value);
            }
        }
    };

    const handleDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onEnter && e.key === "Enter") {
            onEnter(value || "");
        }
    };

    const getLabelTextColor = () => {
        if (disabled) {
            return "text-gray-500";
        }
        if (labelTextColor) {
            return labelTextColor;
        }
        return "text-black";
    };

    const getTextColor = () => {
        if (hasGeneratedValue && value != generatedValue) return "text-white";
        if (hasGeneratedValue) return "text-[#4BFF45]";
        if (disabled) {
            return "text-gray-500";
        }
        if (textColor) {
            return textColor;
        }
        return "text-black";
    };

    const getBackgroundColor = () => {
        if (disabled) {
            return "bg-white";
        }
        if (backgroundColor) {
            return backgroundColor;
        }
        return "bg-white";
    };

    const getBorderColor = () => {
        if (borderColor) {
            return borderColor;
        }
        return "border-thriftlyGrey";
    };

    const undo = () => {
        if (onUndo) onUndo();
    };

    return (
        <div
            className={`flex flex-col justify-center rounded-lg border-2 ${getBorderColor()} p-2 ${getBackgroundColor()} ${className} `}
        >
            {label && (
                <label
                    className={`mx-2 flex flex-row justify-between text-xs font-semibold ${getLabelTextColor()} ${getBackgroundColor()}`}
                    htmlFor={label}
                >
                    {getLabel()}
                    {hasGeneratedValue && (
                        <div className="flex shrink-0 cursor-pointer flex-row text-[#4BFF45]" onClick={undo}>
                            <MdRotateLeft className="h-[16px] w-[16px]" fill="#4BFF45" />
                            UNDO
                        </div>
                    )}
                </label>
            )}
            <div className={`flex flex-row gap-1 px-2 ${getTextColor()}`}>
                {value && <> {prefix}</>}
                <input
                    disabled={disabled}
                    className={`w-full rounded-md border-none focus:outline-none focus:ring-0 ${getBackgroundColor()}`}
                    type={type || "text"}
                    name={getLabel()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleDown}
                    value={value}
                    placeholder={getPlaceholder()}
                    maxLength={maxLength}
                />
                {value && <> {suffix}</>}
            </div>
        </div>
    );
}
