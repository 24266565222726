import { capitalize, Category, Condition, isSet, Process, SalesChannel, StationType } from "common";
import { isMobile } from "react-device-detect";
import { Button } from "../../../components/common/Button";
import GeneratedDropdown from "./GeneratedDropdown";
import GeneratedInputText from "./GeneratedInputField";
import GeneratedInputTextArea from "./GeneratedInputTextArea";

export default function InputFields(props: {
    process: Process;
    processDraft: Process;
    onUpdateDraft: (process: Process) => void;
    generatedProcess: Process | undefined;
    onUpdateGenerated: (process: Process) => void;
    categories: Category[];
}) {
    const { process, processDraft, onUpdateDraft, generatedProcess, onUpdateGenerated, categories } = props;

    return (
        <div className="flex flex-col gap-2 pr-1">
            <GeneratedInputText
                labelTextColor="text-thriftlyGreyLight"
                textColor="text-thriftlyOffWhite"
                backgroundColor="bg-thriftlyBlackBackground"
                borderColor={`border-2 ${!isSet(processDraft.title) ? "border-red-500" : "border-thriftlyGrey"}`}
                label="Title"
                placeholder="Title"
                originalValue={process.title}
                value={processDraft.title}
                generatedValue={generatedProcess?.title}
                onChange={(value) => onUpdateDraft({ ...processDraft, title: value })}
                onUndo={() => {
                    onUpdateDraft({ ...processDraft, title: process.title });
                    onUpdateGenerated({
                        ...generatedProcess,
                        title: undefined,
                    });
                }}
            />
            <div className="flex flex-row justify-between gap-2">
                {Object.values(Condition).map((condition) => {
                    if (processDraft.condition === condition) {
                        return (
                            <Button
                                key={condition}
                                textColor="text-thriftlyOffWhite"
                                backgroundColor="bg-thriftlyGreyDark"
                                className={`w-full ${isMobile ? "h-12" : "h-16"} border-thriftlyGrey`}
                                onClick={() =>
                                    onUpdateDraft({
                                        ...processDraft,
                                        condition: condition,
                                    })
                                }
                            >
                                {capitalize(condition)}
                            </Button>
                        );
                    } else {
                        return (
                            <Button
                                key={condition}
                                textColor="text-thriftlyGreyLight"
                                backgroundColor="bg-thriftlyBlackBackground"
                                className={`w-full ${isMobile ? "h-12" : "h-16"} border-thriftlyGreyDark`}
                                onClick={() =>
                                    onUpdateDraft({
                                        ...processDraft,
                                        condition: condition,
                                    })
                                }
                            >
                                {capitalize(condition)}
                            </Button>
                        );
                    }
                })}
            </div>
            {process.stationType === StationType.StationTypeSoftline ? (
                <div className="grid grid-cols-2 gap-2">
                    <GeneratedInputText
                        labelTextColor="text-thriftlyGreyLight"
                        textColor="text-thriftlyOffWhite"
                        backgroundColor="bg-thriftlyBlackBackground"
                        borderColor={`${!isSet(processDraft?.price) || processDraft?.price === "$" ? "border-red-500" : ""}`}
                        label="Price"
                        placeholder="Price"
                        prefix="$"
                        type="number"
                        originalValue={process.price?.replace("$", "") || ""}
                        value={processDraft.price?.replace("$", "") || ""}
                        generatedValue={generatedProcess?.price?.replace("$", "") || ""}
                        onChange={(value) =>
                            onUpdateDraft({
                                ...processDraft,
                                price: `$${value}`,
                            })
                        }
                        onUndo={() => {
                            onUpdateDraft({
                                ...processDraft,
                                price: process.price,
                            });
                            onUpdateGenerated({
                                ...generatedProcess,
                                price: undefined,
                            });
                        }}
                    />
                    <GeneratedInputText
                        labelTextColor="text-thriftlyGreyLight"
                        textColor="text-thriftlyOffWhite"
                        backgroundColor="bg-thriftlyBlackBackground"
                        label="Brand"
                        placeholder="Brand"
                        type="text"
                        originalValue={process.brand}
                        value={processDraft.brand || ""}
                        generatedValue={generatedProcess?.brand || ""}
                        onChange={(value) => onUpdateDraft({ ...processDraft, brand: value })}
                        onUndo={() => {
                            onUpdateDraft({
                                ...processDraft,
                                brand: process.brand,
                            });
                            onUpdateGenerated({
                                ...generatedProcess,
                                brand: undefined,
                            });
                        }}
                    />
                    <GeneratedInputText
                        labelTextColor="text-thriftlyGreyLight"
                        textColor="text-thriftlyOffWhite"
                        backgroundColor="bg-thriftlyBlackBackground"
                        label="Size"
                        placeholder="Size"
                        type="text"
                        originalValue={process.size}
                        value={processDraft.size || ""}
                        generatedValue={generatedProcess?.size}
                        onChange={(value) => onUpdateDraft({ ...processDraft, size: value })}
                        onUndo={() => {
                            onUpdateDraft({
                                ...processDraft,
                                size: process.size,
                            });
                            onUpdateGenerated({
                                ...generatedProcess,
                                size: undefined,
                            });
                        }}
                    />
                    <GeneratedDropdown
                        label="Category"
                        placeholder="Select a category"
                        value={processDraft.category || ""}
                        options={categories.map((category) => {
                            return {
                                label: category.name!,
                                value: category.name!,
                            };
                        })}
                    />
                    <GeneratedInputText
                        labelTextColor="text-thriftlyGreyLight"
                        textColor="text-thriftlyOffWhite"
                        backgroundColor="bg-thriftlyBlackBackground"
                        label="Color"
                        placeholder="Color"
                        type="text"
                        originalValue={process.color}
                        value={processDraft.color || ""}
                        generatedValue={generatedProcess?.color}
                        onChange={(value) => onUpdateDraft({ ...processDraft, color: value })}
                        onUndo={() => {
                            onUpdateDraft({
                                ...processDraft,
                                color: process.color,
                            });
                            onUpdateGenerated({
                                ...generatedProcess,
                                color: undefined,
                            });
                        }}
                    />
                    <GeneratedInputText
                        labelTextColor="text-thriftlyGreyLight"
                        textColor="text-thriftlyOffWhite"
                        backgroundColor="bg-thriftlyBlackBackground"
                        label="Materials"
                        placeholder="Materials"
                        type="text"
                        originalValue={process.material}
                        value={processDraft.material || ""}
                        generatedValue={generatedProcess?.material}
                        onChange={(value) => onUpdateDraft({ ...processDraft, material: value })}
                        onUndo={() => {
                            onUpdateDraft({
                                ...processDraft,
                                material: process.material,
                            });
                            onUpdateGenerated({
                                ...generatedProcess,
                                material: undefined,
                            });
                        }}
                    />
                </div>
            ) : (
                <>
                    <GeneratedInputText
                        labelTextColor="text-thriftlyGreyLight"
                        textColor="text-thriftlyOffWhite"
                        backgroundColor="bg-thriftlyBlackBackground"
                        borderColor={`border-2 ${!isSet(processDraft?.price) || processDraft?.price === "$" ? "border-red-500" : "border-thriftlyGrey"} w-full`}
                        label="Price"
                        placeholder="Price"
                        prefix="$"
                        type="number"
                        originalValue={process.price?.replace("$", "") || ""}
                        value={processDraft.price?.replace("$", "") || ""}
                        generatedValue={generatedProcess?.price?.replace("$", "") || ""}
                        onChange={(value) => onUpdateDraft({ ...processDraft, price: `$${value}` })}
                        onUndo={() => {
                            onUpdateDraft({ ...processDraft, price: process.price });
                            onUpdateGenerated({ ...generatedProcess, price: undefined });
                        }}
                    />
                    <GeneratedDropdown
                        label="Category"
                        placeholder="Select a category"
                        value={processDraft.category || ""}
                        options={categories.map((category) => {
                            return {
                                label: category.name!,
                                value: category.name!,
                            };
                        })}
                    />
                </>
            )}
            {processDraft.inventory?.id === undefined && (
                <>
                    <div className={"flex flex-row items-center justify-between"}>
                        <div className="flex text-thriftlyGreyLight">Tags</div>
                        <div className="flex w-2/3 pr-2">
                            <Button
                                textColor={
                                    processDraft.tags?.find((t) => t == "Online")
                                        ? "text-thriftlyOffWhite"
                                        : "text-thriftlyGreyLight"
                                }
                                backgroundColor={
                                    processDraft.tags?.find((t) => t == "Online")
                                        ? "bg-thriftlyGreyDark"
                                        : "bg-thriftlyBlackBackground"
                                }
                                className={`w-1/2 ${isMobile ? "h-12" : "h-16"} ${processDraft.tags?.find((t) => t == "Online") ? "border-thriftlyGrey" : "border-thriftlyGreyDark"}`}
                                onClick={() =>
                                    onUpdateDraft({
                                        ...processDraft,
                                        tags: processDraft.tags?.find((t) => t == "Online")
                                            ? processDraft.tags?.filter((t) => t != "Online")
                                            : [...(processDraft.tags || []), "Online"],
                                    })
                                }
                            >
                                Online
                            </Button>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                        <div className="flex text-thriftlyGreyLight">Channels</div>
                        <div className="flex w-2/3 gap-2">
                            <Button
                                textColor={
                                    processDraft.channels?.find((c) => c == SalesChannel.SalesChannelPointOfSale)
                                        ? "text-thriftlyOffWhite"
                                        : "text-thriftlyGreyLight"
                                }
                                backgroundColor={
                                    processDraft.channels?.find((c) => c == SalesChannel.SalesChannelPointOfSale)
                                        ? "bg-thriftlyGreyDark"
                                        : "bg-thriftlyBlackBackground"
                                }
                                className={`w-1/2 ${isMobile ? "h-12" : "h-16"} ${processDraft.channels?.find((c) => c == SalesChannel.SalesChannelPointOfSale) ? "border-thriftlyGrey" : "border-thriftlyGreyDark"}`}
                                onClick={() =>
                                    onUpdateDraft({
                                        ...processDraft,
                                        channels: processDraft.channels?.find(
                                            (c) => c == SalesChannel.SalesChannelPointOfSale
                                        )
                                            ? processDraft.channels?.filter(
                                                  (c) => c != SalesChannel.SalesChannelPointOfSale
                                              )
                                            : [...(processDraft.channels || []), SalesChannel.SalesChannelPointOfSale],
                                    })
                                }
                            >
                                Point of Sale
                            </Button>
                            <Button
                                textColor={
                                    processDraft.channels?.find((c) => c == SalesChannel.SalesChannelOnlineStore)
                                        ? "text-thriftlyOffWhite"
                                        : "text-thriftlyGreyLight"
                                }
                                backgroundColor={
                                    processDraft.channels?.find((c) => c == SalesChannel.SalesChannelOnlineStore)
                                        ? "bg-thriftlyGreyDark"
                                        : "bg-thriftlyBlackBackground"
                                }
                                className={`w-1/2 ${isMobile ? "h-12" : "h-16"} ${processDraft.channels?.find((c) => c == SalesChannel.SalesChannelOnlineStore) ? "border-thriftlyGrey" : "border-thriftlyGreyDark"}`}
                                onClick={() =>
                                    onUpdateDraft({
                                        ...processDraft,
                                        channels: processDraft.channels?.find(
                                            (c) => c == SalesChannel.SalesChannelOnlineStore
                                        )
                                            ? processDraft.channels?.filter(
                                                  (c) => c != SalesChannel.SalesChannelOnlineStore
                                              )
                                            : [...(processDraft.channels || []), SalesChannel.SalesChannelOnlineStore],
                                    })
                                }
                            >
                                Online
                            </Button>
                        </div>
                    </div>
                </>
            )}
            <GeneratedInputTextArea
                labelTextColor="text-thriftlyGreyLight"
                textColor="text-thriftlyOffWhite"
                backgroundColor="bg-thriftlyBlackBackground"
                className="h-48 w-full border-2 border-thriftlyGrey"
                label="Description"
                placeholder="Description"
                originalValue={process.notes}
                value={processDraft.notes || ""}
                generatedValue={generatedProcess?.notes}
                onChange={(value) => onUpdateDraft({ ...processDraft, notes: value })}
                onUndo={() => {
                    onUpdateDraft({ ...processDraft, notes: process.notes });
                    onUpdateGenerated({
                        ...generatedProcess,
                        notes: undefined,
                    });
                }}
            />
        </div>
    );
}
